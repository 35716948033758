<template>
  <div class="page page-xtrading-all-campaign-overview height-100 scroll">

    <v-row v-if="item" class="ma-0 mr-4 ml-4 height-100">

      <v-col cols="12" md="12" lg="4" xl="3">

        <div class="mt-4 mb-4 text-capitalize">
          <h3>{{item.title}}</h3>
        </div>

        <v-list color="transparent" class="pa-0">
          <v-divider color="black" />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><small class="grey--text">{{$t('CAMPAIGN.START_DATE')}}</small></v-list-item-title>
            </v-list-item-content>
            <div><span>{{ $moment(item.start_date).fromNow() }} <small class="grey--text numeric">{{new Date(item.start_date).toLocaleDateString()}}</small></span></div>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><small class="grey--text">{{$t('CAMPAIGN.END_DATE')}}</small></v-list-item-title>
            </v-list-item-content>
            <div><span>{{ $moment(item.end_date).fromNow() }} <small class="grey--text numeric">{{new Date(item.end_date).toLocaleDateString()}}</small></span></div>
          </v-list-item>
        </v-list>

        <v-list color="transparent" class="pt-6">
          <v-divider color="black" />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('PUBLICATION.TITLES')}}</v-list-item-title>
            </v-list-item-content>
            <div><strong class="numeric">{{(+item.publication_count).toLocaleString()}}</strong></div>
          </v-list-item>
          <v-divider color="black" />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.PUBLISHED')}}</small></v-list-item-title>
            </v-list-item-content>
            <div><span :class="[+item.publication_count_published > 0 ? 'success--text' : 'grey--text', 'numeric']">{{(+item.publication_count_published).toLocaleString()}}</span></div>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.UNPUBLISHED')}}</small></v-list-item-title>
            </v-list-item-content>
            <div><span :class="[+item.publication_count_unpublished > 0 ? 'finished--text' : 'grey--text', 'numeric']">{{(+item.publication_count_unpublished).toLocaleString()}}</span></div>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.REFRESH_WAIT')}}</small></v-list-item-title>
            </v-list-item-content>
            <div><span :class="[+item.publication_count_waiting > 0 ? 'info--text' : 'grey--text', 'numeric']">{{(+item.publication_count_waiting).toLocaleString()}}</span></div>
          </v-list-item>
          <v-divider />
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title><small class="grey--text">{{$t('PUBLICATION.DISPATCH_FAILED')}}</small></v-list-item-title>
            </v-list-item-content>
            <div><span :class="[+item.publication_count_error > 0 ? 'error--text' : 'grey--text', 'numeric']">{{(+item.publication_count_error).toLocaleString()}}</span></div>
          </v-list-item>
        </v-list>

      </v-col>

      <v-col class="height-100" cols="12" md="12" lg="8" xl="6">
        <v-card class="height-100 elevation-0 scroll">
          <v-data-table
            fixed-header
            :headers="tableHeader"
            :items="channelSubscriptionItems"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item.title="{item}">
              <div class="d-flex mt-1">
                <v-img
                  contain
                  max-height="25"
                  width="70"
                  :src="getImage(item)"
                />
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      color="grey"
                      dark
                      :disabled="!item.status_output || Object.keys(item.status_output).length === 0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">mdi-file</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-0" dense style="width:350px;" v-if="item.status_output && Object.keys(item.status_output).length > 0">
                    <div>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon class="mr-2">mdi-file</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{$t('CHANNEL_SUBSCRIPTION.GENERATED_FILE')}}</v-list-item-title>
                          <v-list-item-subtitle>{{item.channel.title}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <v-divider />
                    <v-list-item :key="'ou'+i" v-for="(key,i) in Object.keys(item.status_output)">
                      <v-list-item-content>
                        <v-list-item-subtitle class="numeric">
                          <small>{{$moment(item.status_output[key].date).fromNow()}}</small>
                        </v-list-item-subtitle>
                        <v-list-item-title><v-chip color="warning" v-if="item.status_output[key].test_mode === true" x-small class="mr-2">Test</v-chip>{{$t('CHANNEL_SUBSCRIPTION.FILE_' + key.toUpperCase())}}</v-list-item-title>
                        <v-list-item-subtitle class="numeric">
                          <small>{{new Date(item.status_output[key].date).toLocaleString()}} - {{formatFileSize(item.status_output[key].size)}}</small>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div>
                          <v-btn icon small @click="copyText(storageUrl + '/' + item.status_output[key].path)" class="mr-2"><v-icon>mdi-content-copy</v-icon></v-btn>
                          <a :href="storageUrl + '/' + item.status_output[key].path">
                            <v-btn icon small><v-icon>mdi-download</v-icon></v-btn>
                          </a>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="text-center mb-1">
                <span class="black--text">{{ item.alias ? ' (' + item.alias + ')' : '' }}</span>
              </div>
            </template>
            <template v-slot:item.publication_count="{item}">
              <strong class="numeric">{{(+item.publication_count).toLocaleString()}}</strong>
            </template>
            <template v-slot:item.publication_count_error="{item}">
              <span :class="['numeric', +item.publication_count_error > 0 ? 'error--text': 'grey--text', 'numeric']">{{(+item.publication_count_error).toLocaleString()}}</span>
            </template>
            <template v-slot:item.publication_count_published="{item}">
              <span :class="['numeric', +item.publication_count_published > 0 ? 'success--text': 'grey--text', 'numeric']">{{(+item.publication_count_published).toLocaleString()}}</span>
            </template>
            <template v-slot:item.publication_count_unpublished="{item}">
              <span :class="['numeric', +item.publication_count_unpublished > 0 ? 'finished--text': 'grey--text', 'numeric']">{{(+item.publication_count_unpublished).toLocaleString()}}</span>
            </template>
            <template v-slot:item.publication_count_waiting="{item}">
              <span :class="['numeric', +item.publication_count_waiting > 0 ? 'info--text': 'grey--text', 'numeric']">{{(+item.publication_count_waiting).toLocaleString()}}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

    </v-row>

  </div>
</template>


<script>

// import PageHeader from '@/components/PageHeader'
// import Dialog from '@/components/Dialog'
// import StatusLabel from '@/components/StatusLabel'
// import Side from '@/components/Side'
// import DialogRemove from '@/components/DialogRemove'
// import List from '@/components/List'
// import FormCampaignStepper from '@/forms/campaign/stepper/FormCampaignStepper'

const ELEMENT_NAME = 'campaign'
const TABLE_NAME = 'campaigns'
const SERVICE_NAME = 'api_programmatic'


export default {
  name: 'PageXtradingAllCampaignOverview',
  components: {
    // FormCampaignStepper,
    // PageHeader,
    // Dialog,
    // Side,
    // DialogRemove,
    // List,
    // StatusLabel
  },
  data: (_this) => ({
    tableHeader: [
      {
        text: _this.$i18n.t("CHANNEL_SUBSCRIPTION.TITLE"),
        align: "start",
        value: "title",
        class: "font-weight-bold",
        cellClass: "font-weight-bold",
        divider: true,
      },

      {
        text: _this.$i18n.t("COMMON.TOTAL"),
        value: "publication_count",
        divider: true,
      },
      {
        text: _this.$i18n.t("PUBLICATION.PUBLISHED"),
        value: "publication_count_published",
      },
      {
        text: _this.$i18n.t("PUBLICATION.UNPUBLISHED"),
        value: "publication_count_unpublished",
      },
      {
        text: _this.$i18n.t("PUBLICATION.REFRESH_WAIT"),
        value: "publication_count_waiting",
      },
      {
        text: _this.$i18n.t("PUBLICATION.DISPATCH_FAILED"),
        value: "publication_count_error",
      },
    ],
    loading: false,
    item: undefined,
    channelSubscriptionItems: [],

  }),
  props: {
    update:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
    select:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
  },

  watch: {
    update () {
      this.loadItem()
    },
    select (items) {
      this.selected = items
    }
  },

  mounted () {
    this.$queryFilters().get()
    if (this.campaignId) {
      this.loadItem()
        .then(() => {
          this.loadChannelSubscriptionItems()
        })
    }
  },

  computed: {

    storageUrl () {
      return process.env.VUE_APP_FILESHARE_PUBLIC
    },

    campaignId () {
      return this.$router.currentRoute.params.id || undefined
    },

  },

  methods: {

    copyText (txt) {
      navigator.clipboard.writeText(txt)
        .then(() => {
          console.log('copy:', txt)
        })
    },

    formatFileSize (size) {
      let unit = 'KB'
      size = size / 1024 // kb
      if (size > 100) {
        unit = 'MB'
        size = size / 1024 // mb
      }
      return (Math.round(size * 100) / 100) + ' ' + unit
    },

    getImage(item, size = 'original') {
      const url =
        `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.channel.controller_name.toLowerCase()}.png`
    },

    pauseItem () {
      console.warning('pauseItem TODO')
    },

    playItem () {
      console.warning('playItem TODO')
    },

    statusReportValue(name, item) {
      if (item.status_report && item.status_report[name]) {
        return Number(item.status_report[name]).toLocaleString()
      }
      return 0
    },

    // Request

    // search with a timeout
    searchItem(timeout = 150) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItem()
      }, timeout)
    },

    // load items with filters
    loadItem (options = {}) {
      this.loading = true
      this.$queryFilters().save()

      // build query
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        limit: 1,
      }
      const query = this.$queryFormat({
        options: Object.assign({}, defaultOptions, options),
        where: {
          id: this.campaignId,
          publication_count: {
            status: 1,
          },
          status: [1],
        },
      })

      const handleResponse = (response) => {
        if (response.data) {
          this.item = response.data[0]
        } else {
          this.item = undefined
        }
        this.updateSelected()
      }

      // request
      return this.$services[SERVICE_NAME][ELEMENT_NAME]
        .search(query)
        .then(response => {
          handleResponse(response)
        })
        .finally(() => {
          this.loading = false
        })
    },


    loadChannelSubscriptionItems () {

      const handleResponse = (response) => {
        if (response.data && response.data.length > 0) {
          this.channelSubscriptionItems = response.data
        }
      }

      const defaultWhere = {
        status: [1],
        channel: {
          required: true,
        },
      }

      let where = {
          methods: this.item.methods,
          type: this.item.types,
          channel : {
            required: true,
            category: this.item.channel_categories,
          },
          publication_count: {
            campaign_id: this.campaignId,
            status: 1,
          },
      };

      const query = {
        where: Object.assign({}, defaultWhere, where),
      }

      // request
      return this.$services.api_programmatic.channel_subscription
        .search(query)
        .then(response => {
          handleResponse(response)
        })
        .finally(() => {
          this.loading = false
        })

    },


    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    confirmRemoveItems () {
      const ids = this.selected.map(v => v.id)
      this.$services.api_programmatic.campaign.remove({ where: { id: ids }, options: {limit:ids.length}})
        .then(response => {
          this.selected = []
          this.showDialogRemove = false
          this.loadItem()
        })
    },

    updateSelected () {
      //
    },

    // Mutliple items

    removeSelected () {
      this.showDialogRemove = true
    },

    editSelected () {
      this.showEdit = true
      if (this.selected.length > 0) {
      const item = this.selected[0]
        this.$router.push(`/xtrading/campaigns/?edit=${  this.selected[0].id}`)
      }
    },

    selectItems (arr) {
      this.selected = arr
    },

    closeEdit () {
      this.showEdit = false
      this.$router.push('/xtrading/campaigns/')
    },

    // Single item

    updateItems () {
      this.loadItem()
    },

    newItem () {
      this.selected = []
      this.showNew = true
    },

    refreshItem (item) {
      this.loading = true
      return this.$services.api_programmatic.campaign.refresh({where: {id:item.id}})
        .finally(() => {
          this.loading = false
          this.loadItem()
        })
    },

    editItem (item) {
      this.selected = [item]
      this.$router.push(`/xtrading/campaigns/?edit=${this.selected[0].id}`)
      this.showEdit = true
    },

    editPermissionItem (item) {
      this.selected = [item]
      this.showDialogPermissionEdit = true
    },

    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    goToItem (item) {
      item = item ? item : this.selected[0]
      const route = `/xtrading/campaign/${  item.id}`
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route)
      }
    },
    // filter methods
    resetFilters () {
      this.applyFilters()
    },

    applyFilters () {
      this.searchItems()
    },
  }
}
</script>
